<template>
  <div class="projects app py-5 table-orders">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-12">
            <h3>{{ $t('active-projects-label') }}</h3>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6 col-md-2">
            <div class="form-group mb-0">
              <div class="select-box compact">
                <label for="year">{{ $t('year-label') }}</label>
                <Select2 id="year" v-model="year" :options="years" :settings="{minimumResultsForSearch: -1}" v-on:select="changeYear()"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div v-if="!this.projectsLoaded">
              <Loader/>
            </div>
            <table v-if="projects.length" id="active-projects" class="table table-striped table-compact table-responsive-lg dataTable no-footer">
              <thead>
              <tr role="row">
                <th class="sorting_asc" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('project-label') }}</th>
                <th class="sorting" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('start-label') }}</th>
                <th class="sorting" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('amount-label') }}</th>
                <th class="sorting" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('type-label') }}</th>
                <th class="sorting" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('field-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('fridge-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('payment-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1">{{ $t('status-label') }}</th>
                <th class="sorting text-center" tabindex="0" aria-controls="active-projects" rowspan="1" colspan="1"></th>
              </tr>
              </thead>
              <tbody>
              <tr role="row" v-for="item in projects" :key="item.id">
                <router-link :to="'/project/'+item.id">
                  <td class="text-dark col-project" :data-th="$t('project-label')">{{ item.project_name }}</td>
                </router-link>
                <td class="col-project" :data-th="$t('start-label')">{{ createDate(item) }}</td>
                <td class="col-project" :data-th="$t('amount-label')">{{ item.total_area }} m<sup>2</sup></td>
                <td class="col-project" :data-th="$t('type-label')">{{ $t(item.roll_thickness) }}</td>
                <td class="col-project" :data-th="$t('field-label')">{{ item.field }}</td>
                <td class="text-xl-center col-project" :data-th="$t('fridge-label')">
                  <i class="icon-check text-success font-size-12" v-if="item.roll_options && item.roll_options.includes('cooler-truck-label')"></i>
                  <i class="icon-cross-sm text-danger font-size-12" v-if="item.roll_options && !item.roll_options.includes('cooler-truck-label')"></i>
                </td>
                <td class="text-xl-center col-project" :data-th="$t('payment-label')">
                  <i class="icon-cross-sm text-danger font-size-12" v-if="item.payment_status === 'unpaid-label'"></i>
                  <i class="icon-clock text-yellow font-size-12" v-if="item.payment_status === 'waiting-for-payment-label'"></i>
                  <i class="icon-check text-success font-size-12" v-if="item.payment_status === 'paid-label'"></i>
                </td>
                <td class="text-center col-action">
                  <div class="status status-warning" v-if="item.status === 'offer-label'"></div>
                  <div class="status status-success" v-if="item.status === 'order-label'"></div>
                  <i class="icon-clock text-orange" v-if="item.status === 'cutting-started-label'"></i>
                  <i class="icon-check text-black" v-if="item.status === 'cutting-finished-label'"></i>
                  <i class="icon-check text-green" v-if="item.status === 'completed-label'"></i>
                </td>
                <td>
                  <div class="badge-group">
                    <span class="badge text-green">{{ item.trucks_completed }} <i class="icon-truck"></i></span>
                    <span class="badge text-orange">{{ item.trucks_arrived }} <i class="icon-truck"></i></span>
                    <span class="badge">{{ item.trucks_planned }} <i class="icon-truck"></i></span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <span class="text-center d-block text-muted" v-if="noProjects && projectsLoaded">{{ $t('no-projects-label')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {logout} from "@/main";
import Loader from '../components/Modules/Loader'

const axios = require('axios').default;
import moment from 'moment'

export default {
  name: 'projects',
  data(){
    return {
      projects: {},
      projectsLoaded: false,
      noProjects: false,
      years: null,
      year: null
    }
  },
  components: {
    Loader
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append('year', this.year);
      return params;
    }
  },
  methods:{
    changeYear(){
      const self = this
      self.projectsLoaded = false
      self.projects = {}
      self.noProjects = false
      self.getProjects()
    },
    getProjects(){
      const self = this
      axios.get(process.env.VUE_APP_API_URL+`projects`, {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        },
        params: this.axiosParams
      }).then(function (response) {
        self.projects = response.data.data
        self.projectsLoaded = true
        if (self.projects.length === 0) {
          self.noProjects = true
        }
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    },
    createDate(item) {
      if (item.date_type === 'month') {
        return moment(item.date).format('YYYY-MM')
      }
      return moment(item.date).format('YYYY-MM-DD')
    },
    getYears(){
      const self = this
      axios.get(process.env.VUE_APP_API_URL+'years', {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.years = response.data
        if (self.years.length > 0) {
          self.year = self.years[0];
        }
        self.getProjects()
      }).catch(function (error) {
        if(error.response.status === 401){
          logout();
          return false;
        }
        self.$swal(error.message);
      });
      }
    },

  mounted() {
    this.getYears();
  }
}
</script>
