<template>
  <div class="app py-5" v-if="project">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="card">
          <div class="card-body">
            <div class="row my-3">
              <div class="col-12 col-lg-4 pb-3 pr-md-3 border-lg-right pt-lg-3">
                <div class="row">
                  <div class="col-12">
                    <Type :type="project.grass_type" :project="true"/>
                    <span class="font-weight-bold font-size-32 d-block mt-3">{{ project.total_area }} m<sup>2</sup></span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-8">
                <div class="row align-items-center">
                  <div class="col-12 pt-lg-3 font-size-14 font-weight-bold">
                    {{ $t('starting-at-label') }}:
                  </div>
                  <div class="col-6 col-md-6 pt-3">
                    <span class="d-block font-size-14 font-weight-medium">{{ $t('date-label') }}</span>
                    <div class="font-weight-light font-size-20">{{ getDate(project.date) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3 mt-3 border-top py-3">
              <div class="col-12 col-md-4 pr-lg-4 border-md-right">
                <span class="font-weight-medium mb-3 d-block">{{$t('project-details-label')}}</span>
                <span class="font-weight-bold font-size-13 d-block">{{ project.project_name }}</span>
                <span class="text-muted font-size-13 d-block">
                  {{ $t(project.roll_type) }} {{ $t(project.roll_thickness) }}<br>
                  {{ project.calculated_roll_length }} x 1.2 m = {{ project.calculated_roll_size }} m<sup>2</sup>
                </span>
                <span class="text-muted font-size-13 d-block">
                  {{ $t('approximate-label') }} {{ project.calculated_roll_amount }}
                  {{ $t('roll-label') }} / {{ project.calculated_trucks }}
                  {{ $t('truck-label') }}
                </span>
              </div>
              <div class="col-12 col-md-4 pr-lg-4 border-md-right">
                <span class="font-weight-medium mb-3 d-block">{{$t('invoice-details-label')}}</span>
                <router-link :to="'/my-account'">
                  <span class="font-weight-bold font-size-13 d-block">{{ project.name }}</span>
                </router-link>
                <span class="text-muted font-size-13 d-block">{{ project.billing_address.street }}</span>
                <span class="text-muted font-size-13 d-block">{{ project.billing_address.city }}</span>
                <span class="text-muted font-size-13 d-block">{{ project.billing_address.zip }}</span>
                <span class="text-muted font-size-13 d-block">{{ $t(project.billing_address.country) }}</span>
              </div>
              <div class="col-12 col-md-4 pr-lg-4">
                <span class="font-weight-medium mb-3 d-block">{{$t('delivery-address-label')}}</span>
                <span class="text-muted font-size-13 d-block">{{ project.shipping_address.street }}</span>
                <span class="text-muted font-size-13 d-block">{{ project.shipping_address.city }}</span>
                <span class="text-muted font-size-13 d-block">{{ project.shipping_address.zip }}</span>
                <span class="text-muted font-size-13 d-block">{{ $t(project.shipping_address.country) }}</span>
              </div>
              <div class="row border-bottom pb-3">
                <div class="col-12">
                  <button class="btn btn-link btn-pre-square btn-pre-square-green text-decoration-none"
                          type="button" @click="[showEditAddressModal = true]">
                    <i class="icon-edit text-green mr-2"> </i>
                    {{ $t('edit-address-label') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="row border-bottom py-1">
                  <div class="col-4">
                    <p class="text-black text-small mb-0 text-ellipsis">
                      {{ $t('order-number-label') }}
                    </p>
                  </div>
                  <div class="col-8">
                    <p class="text-muted text-small mb-0 text-ellipsis">{{ project.id }}</p>
                  </div>
                </div>
                <div class="row border-bottom py-1">
                  <div class="col-4">
                    <p class="text-black text-small mb-0 text-ellipsis">
                      {{ $t('invoice-number-label') }}
                    </p>
                  </div>
                  <div class="col-8">
                    <p class="text-muted text-small mb-0 text-ellipsis">{{ project.invoice_number || '-' }}</p>
                  </div>
                </div>
                <div class="row border-bottom py-1">
                  <div class="col-4">
                    <p class="text-black text-small mb-0 text-ellipsis">
                      {{ $t('installing-label') }}
                    </p>
                  </div>
                  <div class="col-8">
                    <p class="text-muted text-small mb-0 text-ellipsis">
                      {{ project.installing ? $t('yes-label') : $t('no-label') }}
                    </p>
                  </div>
                </div>
                <div class="row border-bottom py-1">
                  <div class="col-4">
                    <p class="text-black text-small mb-0 text-ellipsis">
                      {{ $t('cooler-truck-label') }}
                    </p>
                  </div>
                  <div class="col-8">
                    <p class="text-muted text-small mb-0 text-ellipsis">
                      {{ project.cooler_truck ? $t('yes-label') : $t('no-label') }} {{ project.cooler_truck ? '(' + project.total_pallets + ' ' + $t('pallets-label') + ')'  : '' }}
                    </p>
                  </div>
                </div>
                <div class="row border-bottom py-1">
                  <div class="col-4">
                    <p class="text-black text-small mb-0 text-ellipsis">
                      {{ $t('paper-tubes-label') }}
                    </p>
                  </div>
                  <div class="col-8">
                    <p class="text-muted text-small mb-0 text-ellipsis">
                      {{ project.paper_tubes ? $t('yes-label') : $t('no-label') }}
                    </p>
                  </div>
                </div>
                <div class="row border-bottom py-1">
                  <div class="col-4">
                    <p class="text-black text-small mb-0 text-ellipsis">{{ $t('field-label') }}</p>
                  </div>
                  <div class="col-8">
                    <p class="text-muted text-small mb-0 text-ellipsis">
                      {{ project.field }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 process-bar">
        <div class="card">
          <div class="card-body">
            <h2 class="mb-3">{{ $t('process-label') }}</h2>
            <div class="row">
              <div class="col-12 text-center font-size-20">
                <span class="font-weight-bold">{{ project.total_truck_area_status }} m<sup>2</sup></span>
                / {{ project.total_truck_area }} m<sup>2</sup>
              </div>
              <div class="col-12 py-2">
                <div class="row justify-content-center">
                  <div class="col-auto font-weight-bold font-size-12 px-2 text-green">
                    {{ project.trucks_completed }} <i class="icon-truck font-size-16 float-right ml-1"></i>
                  </div>
                  <div class="col-auto font-weight-bold font-size-12 px-2 text-orange">
                    {{ project.trucks_arrived }} <i class="icon-truck font-size-16 float-right ml-1"></i>
                  </div>
                  <div class="col-auto font-weight-bold font-size-12 px-2 text-grey">
                    {{ project.trucks_planned }} <i class="icon-truck font-size-16 float-right ml-1"></i>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="progress">
                  <div class="progress-bar bg-success" role="progressbar" :style="{ width: project.progress + '%' }">
                  </div>
                </div>
              </div>
              <div class="col-12 pt-2">
                <div class="row">
                  <div class="col-6 text-center font-weight-medium font-size-14">
                    {{ project.trucks_completed }} /
                    {{ project.trucks_count }} {{$t('truck-label')}}
                  </div>
                  <div class="col-6 text-center font-weight-medium font-size-14">
                    {{ project.total_truck_rolls_completed }} /
                    {{ project.total_truck_rolls }} {{ $t('roll-label') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 my-3">
                <ul class="display-status">
                  <li class="text-green font-size-14 font-weight-medium">
                    {{ project.status === 'offer-label' ? $t('offer-label') : $t('ordered-label') }}
                  </li>
                  <li
                    :class="{ 'text-green': project.status === 'cutting-started-label' || project.status === 'cutting-finished-label' || project.status === 'completed-label' }"
                    class="font-size-14 font-weight-medium">
                    {{ $t('cutting-started-label') }}
                  </li>
                  <li
                    :class="{ 'text-green': project.status === 'cutting-finished-label' || project.status === 'completed-label' }"
                    class="font-size-14 font-weight-medium">
                    {{ $t('cutting-finished-label') }}
                  </li>
                  <li :class="{ 'text-green': project.status === 'completed-label' }"
                    class="font-size-14 font-weight-medium">
                    {{ $t('completed-label') }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PickupDays :pickupDays="project.pickup_days" :coolerTruck="project.cooler_truck" />
    <EditAddress
        v-if="showEditAddressModal"
        @close="showEditAddressModal = false"
    />
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="card mt-4">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <span class="font-weight-medium mb-3 d-block">{{ $t('upload-document-label') }}</span>
                <label for="file-upload" class="custom-file-upload">
                  <i class="icon-upload"></i> {{ $t('upload-document-label') }}
                </label>
                <input id="file-upload" type="file" @change="onFileChange" />
              </div>
            </div>
            <div class="row" v-if="project.attachments.length">
              <div class="col-12">
                <div class="uploaded-file" v-for="file in project.attachments" :key="file.id">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <span class="text-muted text-small">{{ file.uploaded_at }}</span>
                      <span class="file-name text-small">{{ file.name }}</span>
                    </div>
                    <div class="col-4 text-right">
                      <button class="btn btn-success btn-sm" @click="downloadFile(file)">
                        <i class="icon-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { logout } from "@/main";
import Type from "../components/Elements/Type";
import moment from "moment/moment";
import PickupDays from "../components/Modules/PickupDays";
import EditAddress from "@/components/Modals/EditAddress.vue";
const axios = require('axios').default;

export default {
  name: 'ViewProject',
  data() {
    return {
      project: null,
      selectedFile: null,
      uploadedFiles: [],
      showEditAddressModal: false,
      currentAddress: false,
    }
  },
  components: {
    EditAddress,
    Type,
    PickupDays
  },
  methods: {
    getProject() {
      const self = this
      axios.get(process.env.VUE_APP_API_URL + 'projects/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(function (response) {
        self.project = response.data.data
        self.currentAddress = {
          project_id: self.project.id,
          id: self.project.address_id,
          shipping_zip: self.project.shipping_zip,
          shipping_city: self.project.shipping_city,
          shipping_country: self.project.shipping_country,
          shipping_street: self.project.shipping_street,
        }
      }).catch(function (error) {
        if (error.response.status === 401) {
          logout();
          return false;
        }
        self.$swal(error.message);
      });
    },
    getDate(date) {
      if (this.project.date_type === 'specific')
        return moment(date).format('YYYY-MM-DD');
      else
        return moment(date).format('YYYY-MM');
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
      if (this.selectedFile) {
        this.uploadFile();
      }
    },
    uploadFile() {
      if (!this.selectedFile) {
        this.$swal('Please select a file first.');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.selectedFile);

      axios.post(process.env.VUE_APP_API_URL + 'projects/' + this.project.id + '/attachment', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.token}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.$swal('File uploaded successfully!');
        console.log(response.data);
        // Optionally, refresh project data or perform other actions
        this.getProject();
      }).catch(error => {
        this.$swal('File upload failed: ' + error.message);
      });
    },
    downloadFile(file) {
      // Implement file download logic
      axios.post(process.env.VUE_APP_API_URL + 'projects/' + this.project.id + '/download',
        {
          attachment_id: file.id
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.token}`
          },
          responseType: 'blob' // Important for file download
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name);
          document.body.appendChild(link);
          link.click();
        }).catch(error => {
          this.$swal('File download failed: ' + error.message);
        });
    },
  },

  mounted() {
    this.getProject();
  }
}
</script>

<style scoped>
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  border-radius: 8px;
  background-color: #f0fdf4;
  /* Light green background */
  display: flex;
  align-items: center;
  gap: 8px;
  /* Space between icon and text */
}

.custom-file-upload i.icon-upload {
  color: #34d399;
  /* Green icon */
}

.custom-file-upload:hover {
  background-color: #e5f6ed;
  /* Slightly darker green on hover */
}

input[type="file"] {
  display: none;
  /* Hide the default file input */
}

.uploaded-file {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}

.file-name {
  display: block;
  font-weight: 500;
  color: #333;
}

.btn-success,
.btn-danger {
  margin-left: 8px;
  padding: 5px 10px;
  font-size: 12px;
}

.btn-success i,
.btn-danger i {
  margin-right: 4px;
}
</style>

